
import { defineComponent, onMounted, ref, watch } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { getBinVolume, getBuildingVolume } from "@/store/api/KpiServices";
import moment from "moment";


export default defineComponent({
  name: "building-volume",
  props: {
    widgetClasses: String,
    bins: {
      type: Object,
      required: true,
    },
    bins_address: { type: String, required: true },
    date_range: { type: Array as () => Array<any> },
    // waste_type: { type: Array as () => Array<any> },
  },
  components: {},
  setup(props) {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const chartOptions = ref<any>({});
    const series = ref<any[]>([]);
    const binsList = ref(props.bins);
    const bin_address = ref(props.bins_address);
    const date = ref(props.date_range);
    const bin_name = ref<string>("");
    const increase = ref<boolean>(false);
    const payload = ref<any>({
      filter: "week",
      bins_in_building: [],
      date_range: [],
    });
    const avg = ref<any>({
      current_avg: 0,
      previous_avg: 0,
      percent: 0,
    });
    const isBuilding = ref<any>('0');
    //const baseColor = getCSSVariableValue("--bs-warning");
    //const secondaryColor = getCSSVariableValue("--bs-primary-300");

    const loadChartData = () => {
      if (!isBuilding.value) {
        payload.value.bins_in_building = 'All';
      }
      getBuildingVolume(payload.value).then((res) => {
        avg.value.current_avg = res.current_avg;
        avg.value.previous_avg = res.previous_avg;
        avg.value.percent = res.percent;
        increase.value = res.increase;
        chartOptions.value = {
          chart: {
            fontFamily: "inherit",
            type: "bar",
            toolbar: {
              show: true,
            },
          },
          exportEnabled: true,
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: ["55%"],
              endingShape: "rounded",
            },
          },
          legend: {
            show: true,
            style: {
              colors: ["#00A4CCFF", "#fbc531", "#A07855FF", "#F95700FF"],
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            categories: res["catagories"],
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: true,
            },
            labels: {
              style: {
                colors: [],
                fontSize: "12px",
              },
            },
          },
          yaxis: {
            title: {
              text: "Volume ( m³)",
            },
            labels: {
              style: {
                colors: labelColor,
                fontSize: "12px",
              },
              formatter: function (val, index) {
                return val.toFixed(5);
              },
            },
          },
          fill: {
            opacity: 1,
            colors: ["#00A4CCFF", "#fbc531", "#A07855FF", "#F95700FF"],
          },
          states: {
            normal: {
              filter: {
                type: "none",
                value: 0,
              },
            },
            hover: {
              filter: {
                type: "none",
                value: 0,
              },
            },
            active: {
              allowMultipleDataPointsSelection: true,
              filter: {
                type: "none",
                value: 0,
              },
            },
          },
          tooltip: {
            style: {
              fontSize: "12px",
            },
            y: {
              formatter: function (val) {
                return "" + val.toFixed(5) + " m³";
              },
            },
          },
          colors: ["#00A4CCFF", "#fbc531", "#A07855FF", "#F95700FF"],
          grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
              lines: {
                show: true,
              },
            },
          },
        };
        series.value = res["series"];
      });
    };

    onMounted(() => {
      isBuilding.value = localStorage.getItem("building") == "1" ? true : false;
      binsList.value = props.bins;
    });

    watch(
      () => props.bins,
      (first) => {
        binsList.value = first;
        payload.value.bins_in_building = Object.values(binsList.value)[0];
        bin_address.value = Object.keys(binsList.value)[0];
      }
    );

    watch(
      () => props.bins_address,
      (first) => {
        bin_address.value = first;
        updateBin(props.bins[bin_address.value]);
      }
    );

    watch(
      () => props.date_range,
      (first) => {
        if (first == null) {
          first = [];
        }
        date.value = first;
        payload.value.date_range = first;
        loadChartData();
      }
    );

    const updateBin = (val) => {
      if (val == "All") {
        bin_address.value = val;
        payload.value.bins_in_building = val;
      } else {
        bin_address.value = val[0].address;
        payload.value.bins_in_building = val;
      }
      loadChartData();
    };

    const updateChart = (val) => {
      payload.value.filter = val;
      payload.value.date_range = [];
      if(val == 'month'){
        payload.value.date_range.push( moment().subtract(1, 'month').toISOString());
        payload.value.date_range.push( moment().toISOString());
      }else if(val  == '3m'){
        payload.value.date_range.push( moment().subtract(3, 'month').toISOString());
        payload.value.date_range.push( moment().toISOString());
      }else if(val == '6m'){
        payload.value.date_range.push( moment().subtract(6, 'month').toISOString());
        payload.value.date_range.push( moment().toISOString());
      }
      loadChartData();
    };

    return {
      chartOptions,
      isBuilding,
      series,
      binsList,
      updateBin,
      bin_name,
      payload,
      updateChart,
      bin_address,
      increase,
      avg,
    };
  },
});
