
import { defineComponent, onMounted, ref, watch } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { getBInsList, getPickUpChart } from "@/store/api/KpiServices";
import moment from "moment";


export default defineComponent({
  name: "widget-12",
  props: {
    widgetClasses: String,
    chartColor: String,
    chartHeight: String,
    bins: {
      type: Object,
      required: true,
    },
    bins_address: { type: String, required: true },
    date_range: { type: Array as () => Array<any> },
  },
  setup(props) {
    const color = ref(props.chartColor);
    const bin_namee = ref<string>("");

    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const secondaryColor = getCSSVariableValue("--bs-gray-300");
    const baseColor = getCSSVariableValue("--bs-" + color.value);
    const chartOptions = ref<any>({});
    const series = ref<any[]>([]);
    const binsList = ref(props.bins);
    const bin_address = ref(props.bins_address);
    const bin_name = ref<string>("");
    const date = ref(props.date_range);
    const payload = ref<any>({
      filter: "week",
      bins_in_building: [],
      date_range: [],
    });
    const isBuilding = ref<any>('0');


    onMounted(async () => {
      isBuilding.value = localStorage.getItem("building") == "1" ? true : false;
      binsList.value = props.bins;
    });

    const updateChart = (val) => {
      payload.value.filter = val;
      payload.value.date_range = [];
      if (val == 'month') {
        payload.value.date_range.push(moment().subtract(1, 'month').toISOString());
        payload.value.date_range.push(moment().toISOString());
      } else if (val == '3m') {
        payload.value.date_range.push(moment().subtract(3, 'month').toISOString());
        payload.value.date_range.push(moment().toISOString());
      } else if (val == '6m') {
        payload.value.date_range.push(moment().subtract(6, 'month').toISOString());
        payload.value.date_range.push(moment().toISOString());
      }
      loadChartData();
    };

    const loadChartData = () => {
      if (!isBuilding.value) {
        payload.value.bins_in_building = 'All';
      }
      getPickUpChart(payload.value).then((res) => {
        chartOptions.value = {
          chart: {
            fontFamily: "inherit",
            type: "bar",
            height: props.chartHeight,
            toolbar: {
              show: true,
            },
          },
          exportEnabled: true,
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: false,
            },
          },
          legend: {
            show: true,
            style: {
              colors: res["colors"],
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            categories: res["catagories"],
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: true,
            },
            labels: {
              style: {
                colors: labelColor,
                fontSize: "12px",
              },
            },
          },
          yaxis: {
            y: 0,
            offsetX: 0,
            offsetY: 0,
            labels: {
              style: {
                colors: labelColor,
                fontSize: "12px",
              },
            },
          },
          fill: {
            opacity: 1,
            colors: res["colors"],
          },
          states: {
            normal: {
              filter: {
                type: "none",
                value: 0,
              },
            },
            hover: {
              filter: {
                type: "none",
                value: 0,
              },
            },
            active: {
              allowMultipleDataPointsSelection: false,
              filter: {
                type: "none",
                value: 0,
              },
            },
          },
          tooltip: {
            style: {
              fontSize: "12px",
            },
            y: {
              formatter: function (val) {
                return val;
              },
            },
          },
          colors: res["colors"],
          grid: {
            padding: {
              top: 10,
            },
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
              lines: {
                show: true,
              },
            },
          },
        };

        series.value = res["series"];
      });
    };

    watch(
      () => props.bins,
      (first) => {
        binsList.value = first;
        payload.value.bins_in_building = Object.values(binsList.value)[0];
        bin_address.value = Object.keys(binsList.value)[0];
      }
    );

    watch(
      () => props.bins_address,
      (first) => {
        bin_address.value = first;
        updateBin(props.bins[bin_address.value]);
      }
    );

    watch(
      () => props.date_range,
      (first) => {
        if (first == null) {
          first = [];
        }
        date.value = first;
        payload.value.date_range = first;
        loadChartData();
      }
    );

    const updateBin = (value) => {
      bin_address.value = value[0].address;
      payload.value.bins_in_building = value;
      loadChartData();
    };
    return {
      chartOptions,
      series,
      payload,
      binsList,
      updateChart,
      updateBin,
      bin_name,
      bin_address,
      isBuilding
    };
  },
});
