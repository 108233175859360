
import { defineComponent, onMounted, ref, watch } from "vue";
import MixedWidget5 from "@/components/widgets/mixed/Widget5.vue";
import MixedWidget10 from "@/components/widgets/mixed/Widget10.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ChartsWidget2 from "@/components/widgets/charts/Widget2.vue";
import BuildingVolume from "@/components/widgets/charts/BuildingVolume.vue";
import MixedWidget10pickups2 from "@/components/widgets/mixed/Widget10pickups2.vue";

import MixedWidgettotalcost from "@/components/widgets/mixed/Widgettotalcost.vue";
// import ListWidget2 from "@/components/widgets/lists/Widget2.vue";
// import ListWidget5 from "@/components/widgets/lists/Widget5.vue";
import MixedWidgetaveragepick from "@/components/widgets/mixed/Widgetaveragepick.vue";
import MixedWidgettotalwaste from "@/components/widgets/mixed/Widgettotalwaste.vue";
import MixedWidgetavragedistance from "@/components/widgets/mixed/Widgetavragedistance.vue";
import MixedWidgetrouteoverview from "@/components/widgets/mixed/Widgetrouteoverview.vue";
import { getBInsList } from "@/store/api/KpiServices";
import MixedBinsandwastetype from "@/components/widgets/mixed/Binsandwastetype.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
export default defineComponent({
  name: "dashboard",
  components: {
    ChartsWidget2,
    MixedWidget5,
    MixedWidget10,
    MixedWidget10pickups2,
    Datepicker,
    BuildingVolume,
    MixedBinsandwastetype,

    // MixedWidgettotalcost,
    // MixedWidgetaveragepick,

    // ListWidget2,
    // ListWidget5,
    // MixedWidgettotalwaste,
    // MixedWidgetavragedistance,
    // MixedWidgetrouteoverview
    //ChartsWidget4
  },
  setup() {
    const binsList = ref<any>({});
    // const bin_id = ref<any>();
    const bin_address = ref<any>();
    const bins_in_building = ref<any>([]);
    // const waste_types = ref<any[]>([]);
    const date = ref();
    const isBuilding = ref<any>('0');

    onMounted(() => {
      isBuilding.value = localStorage.getItem("building") == "1" ? true : false;
      setCurrentPageTitle("Dashboard");
      getBInsList().then((res) => {
        binsList.value = res;
        bin_address.value = Object.keys(binsList.value)[0];
        if(!isBuilding.value){
          bins_in_building.value = 'All';
        }
        // bin_id.value = binsList.value[0].id.toString();
        // waste_types.value = binsList.value[0].waste_types;
      });
    });

    const updateBin = (value) => {
      // waste_types.value = binsList.value.filter(
      //   (c) => c.id == id
      // )[0].waste_types;
      // bin_id.value = id.toString();
      bin_address.value = value[0].address;
      bins_in_building.value = value;
    };

    const handleDate = (modelData) => {
      date.value = modelData;
    };

    return {
      binsList,
      // bin_id,
      bins_in_building,
      updateBin,
      date,
      handleDate,
      bin_address,
      isBuilding
    };
  },
});
