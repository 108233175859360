
import { getBinsWTOverview } from "@/store/api/KpiServices";
import { defineComponent, onMounted, ref, watch } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

export default defineComponent({
  name: "widget-14",
  props: {
    widgetClasses: String,
    widgetColor: String,
    total_bins: { type: Number, required: true },
  },
  setup(props) {
    const overview = ref<any[]>([]);
    const general = ref<number>(0);
    const glass = ref<number>(0);
    const organic = ref<number>(0);
    const plastic = ref<number>(0);
    const total = ref<any>(props.total_bins);
    const isBuilding = ref<any>('0');

    onMounted(async () => {
      isBuilding.value = localStorage.getItem("building") == "1" ? true : false;
      loadData();
    });

    const loadData = () => {
      getBinsWTOverview().then((res) => {
        general.value =
          res.filter((c) => c.name == "PMD").length > 0
            ? res.filter((c) => c.name == "PMD")[0].total
            : 0;
        glass.value =
          res.filter((c) => c.name == "Glass").length > 0
            ? res.filter((c) => c.name == "Glass")[0].total
            : 0;
        organic.value =
          res.filter((c) => c.name == "Organic").length > 0
            ? res.filter((c) => c.name == "Organic")[0].total
            : 0;
        plastic.value =
          res.filter((c) => c.name == "Plastic").length > 0
            ? res.filter((c) => c.name == "Plastic")[0].total
            : 0;
        general.value += 
          res.filter((c) => c.name == "General").length > 0
            ? res.filter((c) => c.name == "General")[0].total
            : 0;
      });
    };

    watch(
      () => props.total_bins,
      (first) => {
        total.value = first;
      }
    );

    watch(
      () => props.total_bins,
      (first) => {
        total.value = first;
      }
    );

    return {
      organic,
      glass,
      general,
      plastic,
      total,
      isBuilding
    };
  },
});
